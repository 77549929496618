import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import queryString from 'query-string';
import { Observable } from "rxjs"; 
import { environment } from "src/environments/environment";
import {
  PagingRequest,
  PagingResponse,
  Products,
} from "../models";
import { ProductListSearch } from "../models"; 
import { BaseHttpService } from "./base.http.service";

@Injectable({
  providedIn: "root",
})
export class ProductService extends BaseHttpService {

  constructor(protected httpCLient: HttpClient) {
    super(httpCLient);

    this.serviceUri = `${environment.apiUrl}/Products`;
  }

  getProducts(
    request: PagingRequest<ProductListSearch>,
  ): Observable<PagingResponse<Products>> {
    const q = queryString.stringify({
      search: request.search,
      pharmacyId: request.pharmacyId,
      barCode: request.barCode,
      query: request.query,
      page: request.page,
      itemsPerpage: request.itemsPerpage,
      field: request.field,
      order: request.order,
    });
    return this.http.get<PagingResponse<Products>>(
      `${this.serviceUri}?` + q, {headers: this.getDefaultAuthHttpRequestHeader()}
    );
  }
}
