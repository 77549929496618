import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router'; 
import { ScrollListComponent } from './scroll-list/scroll-list.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule, 
    ReactiveFormsModule,
    InfiniteScrollModule
  ],
  declarations: [
    ScrollListComponent
  ],
  exports: [
    ScrollListComponent
  ]
})

export class ListModule { }