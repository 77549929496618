import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthorizedComponent } from "./layout/authorized/authorized.component";
import { AuthorizeInterceptor } from "./interceptors";

import { RxState } from '@rx-angular/state';
import { ShareModule } from './share/share.module';
import { AuthState, AUTH_STATE } from './layout/authorized/states';
import { registerLocaleData, HashLocationStrategy, LocationStrategy  } from '@angular/common';
import localeFr from '@angular/common/locales/id'; 
import { LOCALE_ID, isDevMode } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { LoadingComponent } from './share/component/loading-component/loading.component';
import { TooltipModule  } from 'ngx-bootstrap/tooltip';
import { NgxPrinterModule } from 'ngx-printer';
import { ServiceWorkerModule } from '@angular/service-worker'; 
import { BroadcastService } from './services';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CollapseModule } from 'ngx-bootstrap/collapse';
registerLocaleData(localeFr, 'id');

const lib = [ShareModule, BsDropdownModule.forRoot(), TooltipModule.forRoot(), NgxPrinterModule.forRoot({printOpenWindow: true})];

@NgModule({
  declarations: [AppComponent, AuthorizedComponent, LoadingComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode()
    }),
    BsDatepickerModule.forRoot(),
    ButtonsModule.forRoot(),
    ...lib,
    CollapseModule.forRoot(),
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    {
      provide: AUTH_STATE,
      useFactory: () => new RxState<AuthState>(),
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
    },
    {provide: LOCALE_ID, useValue: 'id'},
    BroadcastService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
