import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from "@angular/common/http";
import { BehaviorSubject, catchError, finalize, Observable, switchMap, throwError, filter, take } from "rxjs";
import { Router, RouterStateSnapshot } from "@angular/router";
import { AuthenticateService } from "../services/authenticate.service";
import { DetailError, IErrorResponse } from "../models";
import { SessionsService } from "../services";
import Swal from "sweetalert2";

@Injectable()
export class AuthorizeInterceptor implements HttpInterceptor {
  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
  constructor(
    private _authService: AuthenticateService,
    private _router: Router, private sessionService: SessionsService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | any {
    return next.handle(request).pipe(
      catchError(
        error => {
          if (error.status === 401) {
            return this.handle401Error({ request: request, next: next });
          } else {
            return this.handleError(error);
          }
        }
      )
    );
  }

  private handleError = (err: any) => {
    // todo: show toast message in case of server error

    // throw error for component to handle 
    var response = <IErrorResponse>err.error;
    var detailErrors = [];
    var errorMessage = '';

    if (err.status === 0) {
      Swal.fire({
        title: 'Terjadi Kesalahan',
        text: 'Silahkan Refresh Ulang.',
        icon: 'error',
        showCloseButton: false,
        showCancelButton: true,
        confirmButtonText: 'Refresh',
        cancelButtonText: 'Kembali',
      }).then(
        result => {
          if (result) {
            window.location.reload();
          }
        });
    } else {
      try {
        detailErrors = JSON.parse(response.detail);
      }
      catch (exception) { }

      if ((detailErrors as Array<DetailError>).length != 0)
        errorMessage = detailErrors[0].ErrorMessage;
      else
        errorMessage = response?.detail;
    }
    return throwError(() => ({
      code: response?.code,
      detail: errorMessage,
      statusCode: err.status
    } as IErrorResponse));
  };

  private handle401Error(rq: any) {
    const state: RouterStateSnapshot = this._router.routerState.snapshot;
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next("");

      return this.sessionService.refreshToken().pipe(
        switchMap(data => {
          const response = <any>data;
          this.tokenSubject.next(response.accessToken);

          localStorage.setItem('token', response.accessToken);

          const httpHeaders = new HttpHeaders({
            'Content-Type': rq.request.headers.get('Content-Type') || "",
            'Authorization': `Bearer ${this.tokenSubject.value}`,
            'platform': 'pos'
          });

          return rq.next.handle(rq.request.clone({
            headers: httpHeaders
          }));
        }),
        catchError(error => {
          return <any>this._authService._logout(state);
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );

    } else {
      this.isRefreshingToken = false;

      return this.tokenSubject.pipe(
        filter(token => token != ""),
        take(1),
        switchMap(token => {
          const httpHeaders = new HttpHeaders({
            'Content-Type': rq.request.headers.get('Content-Type') || "",
            'Authorization': `Bearer ${token}`,
            'platform': 'pos'
          });
          return rq.next.handle(rq.request.clone({
            headers: httpHeaders
          }));
        }));

    }
  }
}
