//our root app component
import { Component, EventEmitter, OnInit, Input, Output, TemplateRef } from "@angular/core";    

@Component({
  selector: "app-scroll-list",
  templateUrl: "./scroll-list.component.html",
  styleUrls: ["./scroll-list.component.scss"],
})

export class ScrollListComponent implements OnInit {  
  @Input('module') moduleName: string = ""; 
  @Input('customClass') customClass: string = "scroll-container"; 
  @Input('totalRecords') totalRecords: number = 0;
  @Input('currentPage') currentPage: number = 1;
  @Input('resultPerPage') resultPerPage: number = 15; 
  @Input('throttle') throttle: number= 50;
  @Input('distance') distance: number= 2; 
  @Input('dataTemplate') dataTemplate: TemplateRef<any> | any= undefined;
  @Output('onScroll') onScroll: EventEmitter<any> = new EventEmitter();  
  constructor() { 
  }
  ngOnInit() { 
  } 
  onScrollEvent(): void {    
    let maxPage = Math.ceil(this.totalRecords / this.resultPerPage);
    if(this.currentPage == maxPage)
    return;

    this.currentPage++;
    var customEvent: any = { 
      page: this.currentPage, 
    };

    this.onScroll.emit(customEvent);
  }
}
