<!-- <pre style="display: none;">
  {{'jsHeapSizeLimit: '+ window.performance.memory['jsHeapSizeLimit']}}
  {{'totalJSHeapSize: '+ window.performance.memory['totalJSHeapSize']}}
  {{'usedJSHeapSize: '+ window.performance.memory['usedJSHeapSize']}}
  Total: {{window.performance.memory['usedJSHeapSize'] / 1024 /1024}} mb
  percentage: {{window.performance.memory['usedJSHeapSize'] / window.performance.memory['totalJSHeapSize'] * 100}} %
</pre> -->
<div [ngClass]="!toggleLayer?'d-none':'disable-background'"> 
  <button class="btn btn-link" type="button" (click)="clickToggle()">
    <img src="assets/icons/lock-circle.svg" alt="lock circle"> 
  </button>
</div>
  <header class="align-items-center bg-white d-flex">  
    <div class="menu">
      <div class="btn-group" placement="bottom right" dropdown>
        <button id="button-basic" dropdownToggle type="button" class="btn btn-link dropdown-toggle px-2"
                aria-controls="dropdown-basic">
                <img src="assets/icons/power.svg" alt="power"> 
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-x" role="menu" aria-labelledby="button-basic">
          <li role="menuitem" (click)="onPharmacy()" *ngIf="currentUser?.stakeholderTypeId != 4"><a class="dropdown-item" href="#">Apotek</a></li>
          <li role="menuitem" routerLink="/session"><a class="dropdown-item" href="#">Session</a></li>
          <li *ngIf="sess?.status=='OPEN'" role="menuitem" (click)="onClosing()"><a class="dropdown-item" href="#">Closing</a></li>
          <li class="divider dropdown-divider"></li>
          <li role="menuitem" (click)="onLogout()"><a class="dropdown-item" href="#">Logout</a></li>
        </ul>
      </div>
      <button class="btn btn-link" placement="bottom" tooltip="Open Customer Screen">
        <img src="assets/icons/dual-screen.svg" alt="dual screen" width="24px"> 
      </button>
      <!-- <button class="btn btn-link">
        <img src="assets/icons/wifi-rounded.svg" alt="wifi"> 
      </button> -->
      <button class="btn btn-link" [routerLink]="'/pos/history'" placement="bottom" tooltip="Order History">
        <img src="assets/icons/receipt.svg" alt="receipt"> 
      </button> 
      <button class="btn btn-link" placement="bottom" tooltip="Pickup Order">
        <img src="assets/icons/pickup.svg" [routerLink]="'/pos/pickup'" alt="pickup"> 
      </button>
      <button class="btn btn-link" (click)="clickToggle()" placement="bottom" tooltip="Lock Screen">
        <img src="assets/icons/lock-circle.svg" alt="lock circle"> 
      </button>
      <button class="btn btn-link" (click)="clickRefresh()" placement="bottom" tooltip="Refresh" style="vertical-align: baseline;">
        <img src="assets/icons/refresh.svg" alt="refresh"> 
      </button>
    </div>
    <div class="menu py-menu" *ngIf="currentUser?.stakeholderName" placement="bottom" tooltip="{{currentUser?.uniqueName}}">
      <img class="icon-circle" src="assets/icons/store-icon.svg" alt="store icon"> 
      <span>{{currentUser?.stakeholderName}}</span>
    </div> 

    <!-- <button class="btn btn-link p-0"> 
      <img src="assets/icons/bars.svg" alt="bars"> 
    </button> -->
    <!-- <strong>POINT OF SALES (POS)</strong>   -->
    <div class="flex-fill"></div>
    <!-- <div class="branches btn-group-sm d-flex flex-column justify-content-between">
      <button class="btn btn-hoverable py-0 text-normal" type="button">
        <span>{{currentUser?.uniqueName}}</span>
      </button>
    </div>  -->
<div class="row fx">
  <div class="col-12 p-0"><img class="srx-icon" src="assets/images/securerx.png" alt="logo" [routerLink]="'/pos'"> </div>
  <div class="col-12 version">v. {{version}}</div>
</div>
  
  </header>
  <router-outlet></router-outlet>