import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import queryString from 'query-string';
import { Observable, map, shareReplay, timer } from "rxjs"; 
import { environment } from "src/environments/environment"; 
import { IFilterable, IPagedQuery, IPagedResult, PagingRequest } from "../models";
import { CashClosingPayload, CashPayload, Pharmacies, Session, SessionCreate, SessionDetail, Sessions } from "../models/session.model";
import { BaseHttpService } from "./base.http.service";

@Injectable({
  providedIn: "root",
})
export class SessionsService extends BaseHttpService {

  constructor(protected httpCLient: HttpClient) {
    super(httpCLient);

    this.serviceUri = `${environment.apiUrl}`;
  }
 
  private _time$: Observable<Date> = timer(0, 1000).pipe(
    map(tick => new Date()),
    shareReplay(1)
  );

  get time() {
    return this._time$;
  }

  getPosSessions(): Observable<Session[]> { 
    return this.http.get<Session[]>("sessions", {headers: this.getDefaultAuthHttpRequestHeader()});
  }

  getSessions(
    request: PagingRequest<IFilterable>,
  ): Observable<IPagedResult<Sessions>> {
    const q = queryString.stringify({
      page: request.page,
      itemsPerpage: request.itemsPerpage,
      search: request.search, 
      pharmacyId: request.pharmacyId,  
    }); 
    return this.http.get<IPagedResult<Sessions>>(
      `${this.serviceUri}/sessions?` + q, {headers: this.getDefaultAuthHttpRequestHeader()}
    );
  }
  getSession(path:any, pharmacyId:any): Observable<SessionDetail> {  
    const q = queryString.stringify({ 
      pharmacyId: pharmacyId, 
    }); 
    return this.http.get<SessionDetail>(
      `${this.serviceUri}/sessions/${path}?` + q, {headers: this.getDefaultAuthHttpRequestHeader()}
    );
  } 
  postSession(formData: SessionCreate): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUri}/sessions`,
      formData, {headers: this.getDefaultAuthHttpRequestHeader()}
    );
  }
  postClosing(id:number, formData: CashClosingPayload): Observable<any> {
    return this.http.put<any>(
      `${this.serviceUri}/sessions/${id}`,
      formData, {headers: this.getDefaultAuthHttpRequestHeader()}
    );
  }
  postTempClosing(id:number, formData: CashPayload): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUri}/sessions/${id}/cash/closing`,
      formData, {headers: this.getDefaultAuthHttpRequestHeader()}
    );
  }
  postCash(id:number, formData: CashPayload, type:string): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUri}/sessions/${id}/cash/${type}`,
      formData, {headers: this.getDefaultAuthHttpRequestHeader()}
    );
  }
  getPharmacies(
    request: PagingRequest<IFilterable>,
  ): Observable<IPagedResult<Pharmacies>> {
    const q = queryString.stringify({
      page: request.page,
      itemsPerpage: request.itemsPerpage,
      search: request.search, 
    }); 
    return this.http.get<IPagedResult<Pharmacies>>(
      `${this.serviceUri}/pharmacies?` + q, {headers: this.getDefaultAuthHttpRequestHeader()}
    );
  }

  refreshToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    return this.http.get(`${this.serviceUri}/accounts/token/refresh?refreshToken=${refreshToken}`, { headers: this.defaultHeader });
  }
}
