import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import queryString from 'query-string';
import { Observable } from "rxjs"; 
import { environment } from "src/environments/environment";
import { 
  ReportRequest,
} from "../models"; 
import { BaseHttpService } from "./base.http.service";

@Injectable({
  providedIn: "root",
})
export class ReportService extends BaseHttpService {

  constructor(protected httpCLient: HttpClient) {
    super(httpCLient);

    this.serviceUri = `${environment.apiUrl}/reports`;
  }

  getOrderReport(
    request: ReportRequest,
  ): Observable<any> {
    const q = queryString.stringify({
        sessionId: request.sessionId,
        pharmacyId: request.pharmacyId,
        dateFrom: request.dateFrom,
        dateUntil: request.dateUntil,
        orderStatusId: request.orderStatusId,
        paymentMethodId: request.paymentMethodId,
    });
    return this.http.get<any>(
      `${this.serviceUri}/orders/session/${request.sessionId}/Excel?` + q, {headers: this.getDefaultAuthHttpRequestHeader(), responseType:'blob' as 'json'}
    );
  }
}
