import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import queryString from 'query-string';
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import {
  Cart,
  CartPromos,
  CartRequest,
  Carts,
  CheckoutResponse,
  customerPrescriptions,
  CustomerRedeem,
  PagingRequest,
  PagingResponse,
  postCart,
  postCheckout,
  postConcoction,
  postPrescriptionCart,
  productPrescriptions,
  Promos,
  putCart,
  putCustomerCart,
  Redeems,
  removeCart,
  removeCartById,
} from "../models";
import { BaseHttpService } from "./base.http.service";

@Injectable({
  providedIn: "root",
})
export class CartService extends BaseHttpService {

  private qC = new Subject<{ pid: any, rp: boolean }>();
  public refreshCart$ = this.qC.asObservable();

  constructor(protected httpCLient: HttpClient) {
    super(httpCLient);

    this.serviceUri = `${environment.apiUrl}`;
  }

  sendQc(rp: boolean = false) {
    const promoId: number = Number(localStorage.getItem('pu') ? (JSON.parse(localStorage.getItem('pu') || '')?.promoId) : 0);
    const uniqueId: number = Number(localStorage.getItem('pu') ? (JSON.parse(localStorage.getItem('pu') || '')?.uniqueId) : 0);
    this.qC.next({ pid: uniqueId == Number(localStorage.getItem('uniqueId')) ? promoId : 0, rp: rp });
  }
  getQc(): Observable<any> {
    return this.qC.asObservable()
  }

  getCarts(
    request: PagingRequest<any>,
  ): Observable<PagingResponse<Carts>> {
    const q = queryString.stringify({
      search: request.search,
      pharmacyId: request.pharmacyId,
      sessionId: request.sessionId,
      query: request.query,
      page: request.page,
      orderStatusId: request.orderStatusId,
      itemsPerpage: request.itemsPerpage,
      field: request.field,
      order: request.order,
    });
    return this.http.get<PagingResponse<Carts>>(
      `${this.serviceUri}/carts?` + q, { headers: this.getDefaultAuthHttpRequestHeader() }
    );
  }
  getCart(
    request: CartRequest,
  ): Observable<Cart> {
    const body = {
      promoId: request.promoId,
      usePoints: request.usePoints || 0,
      sessionId: request.sessionId,
      pharmacyId: request.pharmacyId,
      redeemProducts: request.redeemProducts,
      shippingPrice: request.shippingPrice
    };
    return this.http.post<Cart>(
      `${this.serviceUri}/carts/${request.uniqueId}/detail`, body, { headers: this.getDefaultAuthHttpRequestHeader() }
    );
  }

  getCartPromos(
    request: PagingRequest<any>,
  ): Observable<PagingResponse<CartPromos>> {
    const q = queryString.stringify({
      search: request.search,
      name: request.name,
      page: request.page,
      itemsPerpage: request.itemsPerpage,
      field: request.field,
      order: request.order,
    });
    return this.http.get<PagingResponse<CartPromos>>(
      `${this.serviceUri}/banners?` + q, { headers: this.getDefaultAuthHttpRequestHeader() }
    );
  }

  postCart(
    request: postCart, productId: number
  ): Observable<boolean> {
    const body = {
      uniqueId: request.uniqueId,
      customerId: request.customerId,
      sessionId: request.sessionId,
      pharmacyId: request.pharmacyId,
    };
    return this.http.post<boolean>(
      `${this.serviceUri}/carts/${productId}`, body, { headers: this.getDefaultAuthHttpRequestHeader() }
    );
  }

  putCart(
    request: putCart, cartId: number
  ): Observable<boolean> {
    const body = {
      qty: request.qty,
      sessionId: request.sessionId,
      pharmacyId: request.pharmacyId,
    };
    return this.http.put<boolean>(
      `${this.serviceUri}/carts/${cartId}`, body, { headers: this.getDefaultAuthHttpRequestHeader() }
    );
  }

  putCustomerCart(
    request: putCustomerCart
  ): Observable<boolean> {
    const body = {
      customerId: request.customerId,
      sessionId: request.sessionId,
      pharmacyId: request.pharmacyId,
    };
    return this.http.put<boolean>(
      `${this.serviceUri}/carts/${request.uniqueId}/Customer`, body, { headers: this.getDefaultAuthHttpRequestHeader() }
    );
  }
  removeCart(obj: removeCart): Observable<boolean> {
    const q = queryString.stringify({
      sessionId: obj.sessionId,
      pharmacyId: obj.pharmacyId,
    });
    return this.http.delete<boolean>(
      `${this.serviceUri}/carts/unique/${obj.uniqueId}?` + q, { headers: this.getDefaultAuthHttpRequestHeader() }
    );
  }
  removeCartById(obj: removeCartById): Observable<boolean> {
    const q = queryString.stringify({
      sessionId: obj.sessionId,
      pharmacyId: obj.pharmacyId,
    });
    return this.http.delete<boolean>(
      `${this.serviceUri}/carts/${obj.id}?` + q, { headers: this.getDefaultAuthHttpRequestHeader() }
    );
  }
  getPromos(
    request: PagingRequest<any>,
  ): Observable<PagingResponse<Promos>> {
    const q = queryString.stringify({
      search: request.search,
      name: request.name,
      page: request.page,
      itemsPerpage: request.itemsPerpage,
      field: request.field,
      order: request.order,
    });
    return this.http.get<PagingResponse<Promos>>(
      `${this.serviceUri}/carts/${request?.uniqueId}/Promo?` + q, { headers: this.getDefaultAuthHttpRequestHeader() }
    );
  }
  postConcocnationCart(
    body: postConcoction
  ): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.serviceUri}/carts/concoction`, body, { headers: this.getDefaultAuthHttpRequestHeader() }
    );
  }

  postCheckout(
    body: postCheckout, uniqueId: number
  ): Observable<CheckoutResponse> {
    return this.http.post<CheckoutResponse>(
      `${this.serviceUri}/carts/${uniqueId}/checkout`, body, { headers: this.getDefaultAuthHttpRequestHeader() }
    );
  }
  getRedeems(
    request: PagingRequest<any>,
  ): Observable<PagingResponse<Redeems>> {
    const q = queryString.stringify({
      search: request.search,
      page: request.page,
      itemsPerpage: request.itemsPerpage,
      field: request.field,
      order: request.order,
    });
    return this.http.get<PagingResponse<Redeems>>(
      `${this.serviceUri}/carts/${request.uniqueId}/product/redeem?` + q, { headers: this.getDefaultAuthHttpRequestHeader() }
    );
  }
  validateRedeem(
    body: any
  ): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.serviceUri}/carts/${body.uniqueId}/product/redeem/validate`, { redeemProducts: body.redeemProducts }, { headers: this.getDefaultAuthHttpRequestHeader() }
    );
  }
  getCustomerRedeems(
    request: PagingRequest<any>,
  ): Observable<PagingResponse<CustomerRedeem>> {
    const q = queryString.stringify({
      search: request.search,
      name: request.name,
      page: request.page,
      itemsPerpage: request.itemsPerpage,
      field: request.field,
      order: request.order,
      pharmacyId: request.pharmacyId
    });
    return this.http.get<PagingResponse<CustomerRedeem>>(
      `${this.serviceUri}/Products/PurchaseWithPurchase?` + q, { headers: this.getDefaultAuthHttpRequestHeader() }
    );
  }
  getBundles(
    request: PagingRequest<any>, productId: number
  ): Observable<PagingResponse<Redeems>> {
    const q = queryString.stringify({
      pharmacyId: request.pharmacyId,
    });
    return this.http.get<PagingResponse<Redeems>>(
      `${this.serviceUri}/Products/${productId}/Bundle?` + q, { headers: this.getDefaultAuthHttpRequestHeader() }
    );
  }

  postBundleCart(
    request: postCart, bundleId: number
  ): Observable<boolean> {
    const body = {
      uniqueId: request.uniqueId,
      customerId: request.customerId,
      sessionId: request.sessionId,
      pharmacyId: request.pharmacyId,
    };
    return this.http.post<boolean>(
      `${this.serviceUri}/carts/Bundle/${bundleId}`, body, { headers: this.getDefaultAuthHttpRequestHeader() }
    );
  }
  getCustomerPrescriptions(
    request: PagingRequest<any>,
  ): Observable<PagingResponse<customerPrescriptions>> {
    const q = queryString.stringify({
      search: request.search,
      name: request.name,
      page: request.page,
      itemsPerpage: request.itemsPerpage,
      field: request.field,
      customerId: request.customerId,
    });
    return this.http.get<PagingResponse<customerPrescriptions>>(
      `${this.serviceUri}/prescription?` + q, { headers: this.getDefaultAuthHttpRequestHeader() }
    );
  }
  postPrescriptionCart(
    request: postPrescriptionCart, prescriptionId: number
  ): Observable<boolean> {
    const body = {
      uniqueId: request.uniqueId,
      customerId: request.customerId,
      batchNumber: request.batchNumber,
      sessionId: request.sessionId,
      pharmacyId: request.pharmacyId,
    };
    return this.http.post<boolean>(
      `${this.serviceUri}/carts/prescription/${prescriptionId}`, body, { headers: this.getDefaultAuthHttpRequestHeader() }
    );
  }
  postPrescriptionProductCart(
    request: postPrescriptionCart, prescriptionProductId: number
  ): Observable<boolean> {
    const body = {
      uniqueId: request.uniqueId,
      customerId: request.customerId,
      batchNumber: request.batchNumber,
      sessionId: request.sessionId,
      pharmacyId: request.pharmacyId,
    };
    return this.http.post<boolean>(
      `${this.serviceUri}/carts/prescription/product/${prescriptionProductId}`, body, { headers: this.getDefaultAuthHttpRequestHeader() }
    );
  }
  getPrescriptionProduct(
    request: PagingRequest<any>, id: number
  ): Observable<PagingResponse<productPrescriptions>> {
    const q = queryString.stringify({
      pharmacyId: request.pharmacyId,
    });
    return this.http.get<PagingResponse<productPrescriptions>>(
      `${this.serviceUri}/prescription/${id}?` + q, { headers: this.getDefaultAuthHttpRequestHeader() }
    );
  }
}
