import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import queryString from 'query-string';
import { Observable } from "rxjs"; 
import { environment } from "src/environments/environment"; 
import { OrderDetail, Orders, PagingRequest, PagingResponse, PickupOrders, PickupSummary, RefundOrder } from "../models";
import { BaseHttpService } from "./base.http.service";

@Injectable({
  providedIn: "root",
})
export class OrderService extends BaseHttpService { 

  constructor(protected httpCLient: HttpClient) {
    super(httpCLient);

    this.serviceUri = `${environment.apiUrl}`;
  }
  getOrders(
    request: PagingRequest<any>,
  ): Observable<PagingResponse<Orders>> { 
    const q = queryString.stringify({
      search: request.search,
      pharmacyId: request.pharmacyId,
      sessionId:request.sessionId,
      query: request.query,
      page: request.page,
      orderStatusId: request.orderStatusId,
      itemsPerpage: request.itemsPerpage,
      field: request.field,
      order: request.order,
    });
    return this.http.get<PagingResponse<Orders>>(
      `${this.serviceUri}/orders?` + q, {headers: this.getDefaultAuthHttpRequestHeader()}
    );
  }

  getOrder(id:number, pharmacyId:number): Observable<OrderDetail> {
    const q = queryString.stringify({
      pharmacyId: pharmacyId,
    });
    return this.http.get<OrderDetail>(
      `${this.serviceUri}/orders/${id}/Receipt?` + q, {headers: this.getDefaultAuthHttpRequestHeader()}
    );
  } 

  getPickupOrders(
    request: PagingRequest<any>,
  ): Observable<PagingResponse<PickupOrders>> { 
    request = Object.fromEntries(Object.entries(request).filter(([_, v]) => v != null));
    if(request.date){
      var x = new Date(new Date(request.date?.[0]).setHours(0, 0, 0, 0)); 
      var y = new Date(new Date(request.date?.[1]).setHours(23, 59, 59, 59)); 
      request.date = [x.toISOString(), y.toISOString()]
    }
    if(request.sort){
      var field = request.sort.split(',')[0];
      var order = request.sort.split(',')[1];
      request.field = request.sort.split(',')[0];
      request.order = Number(request.sort.split(',')[1]);
    }
    
    const q = queryString.stringify({
      search: request.search,
      pharmacyId: request.pharmacyId, 
      query: request.query,
      page: request.page,
      orderStatusId: request.orderStatusId,
      itemsPerpage: request.itemsPerpage,
      field: request.field,
      order: request.order,
      dateFrom: request.date?.[0],
      dateUntil:request.date?.[1],
    });
    return this.http.get<PagingResponse<PickupOrders>>(
      `${this.serviceUri}/orders/pickup?` + q, {headers: this.getDefaultAuthHttpRequestHeader()}
    );
  }
  getPickupSummary(
    request: PagingRequest<any>,
  ): Observable<PickupSummary[]> { 
    request = Object.fromEntries(Object.entries(request).filter(([_, v]) => v != null));
    const q = queryString.stringify({  
      pharmacyId: request.pharmacyId, 
    });
    return this.http.get<PickupSummary[]>(
      `${this.serviceUri}/orders/pickup/summary?` + q, {headers: this.getDefaultAuthHttpRequestHeader()}
    );
  } 
  refundOrder(id:number, body:RefundOrder): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUri}/orders/${id}/refund`, body, {headers: this.getDefaultAuthHttpRequestHeader()}
    );
  } 
  updatePickupOrder(id:number, status:string): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUri}/orders/${id}/${status}`, {}, {headers: this.getDefaultAuthHttpRequestHeader()}
    );
  } 
}
