import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  concat,
  filter,
  map,
  Observable,
  of,
  take,
  tap,
} from "rxjs";
import { CurrentUser, SessionDetail } from "../models"; 
import { StorageService } from "./storage.service";
import jwt_decode from "jwt-decode";
import { Router, RouterStateSnapshot } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthenticateService {
  constructor(private _storage: StorageService, private _router:Router) {}

  persistToken(item: any) {
    this._storage.set("token", item?.accessToken);
    this._storage.set("refreshToken", item?.refreshToken);
  }

  persistSession(item: any) { 
    this._storage.set("session", item);
  }

  logout(): void {
    this.clearToken();
  }

  _logout(state: RouterStateSnapshot) {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('pharmacyId');

    if (state === null)
      this._router.navigate(['/login']);
    else
      this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
  }

  getToken(): Observable<string> {
    return of(this._storage.get("token") || "");
  } 

  clearToken(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('pharmacyId');

    this._router.navigate(['/login']);
    setTimeout(() => {
      window.location.reload();
    }, 10);
  }
  clearSession(): void {
    localStorage.removeItem('session');   
    this._router.navigate(['/session']);
  }
  onLogout(): void{
    if(localStorage.getItem('session'))
    this.clearSession();
    else
    this.clearToken();
  }
  private _user$ = new BehaviorSubject<CurrentUser | null>(null);
  isAuthenticated(): Observable<boolean> {
    return this.getUser().pipe(map(u => !!u));
  }

  getUser(): Observable<CurrentUser | null> {
    return concat(
      this._user$.pipe(
        take(1),
        filter(u => !!u),
      ),
      this.getCurrentUser().pipe(
        filter(u => !!u),
        tap(u => this._user$.next(u)),
      ),
      this._user$.asObservable(),
    );
  }

  getCurrentUser(): Observable<CurrentUser | null> {
    const token = this._storage.get("token");
    if (!token) {
      return of(null);
    }

    let claims: any;

    try {
      claims = jwt_decode(token);
    } catch {
      return of(null);
    }

    // check expiry
    if (!claims || Date.now().valueOf() > claims.exp * 1000) {
      return of(null);
    }

    const mapped = Object.entries(claims).map(([type, value]) => ({type, value}));  

    const user: CurrentUser = {
      userId : claims.userID,
      uniqueName : claims.unique_name,
      email : claims.email,
      mobilePhone : mapped[5].value,
      culture : claims.culture,
      stakeholderTypeId : claims.stakeholderTypeId,
      stakeholderId : claims.stakeholderId,
      stakeholderName : claims.stakeholderName,
      role : claims.role,
      admin : <boolean>claims?.admin,
      permissions : JSON.parse(claims.permission, this.toCamelCase)
    };

    return of(user);
  }
  
  private _userSession$ = new BehaviorSubject<SessionDetail | null>(null);
  isSessionAuthenticated(): Observable<boolean> {
    return this.getUserSession().pipe(map(u => !!u));
  }

  getUserSession(): Observable<SessionDetail | null> {
    return concat(
      this._userSession$.pipe(
        take(1),
        filter(u => !!u),
      ),
      this.getCurrentUserSession().pipe(
        filter(u => !!u),
        tap(u => this._userSession$.next(u)),
      ),
      this._userSession$.asObservable(),
    );
  }

  getCurrentUserSession(): Observable<SessionDetail | null> {
    const token = this._storage.get("session");
    if (!token) {
      return of(null);
    }

    let claims: any;

    try {
      claims = JSON.parse(token);
    } catch {
      return of(null);
    }

    // check expiry
    if (!claims) {
      return of(null);
    }
    const user: SessionDetail = {
      cashLogs: claims.cashLogs,
      sessionId: claims.sessionId,
      sessionNumber: claims.sessionNumber,
      startDate: claims.startDate,
      endDate: claims.endDate,
      status: claims.status,
      openingBalance: claims.openingBalance,
      expectedBalance: claims.expectedBalance,
      closingBalance: claims.closingBalance,
      openingBy: claims.openingBy,
      openingNotes: claims.openingNotes,
      closingBy: claims.closingBy,
      closingNotes: claims.closingNotes,
    };
    
    // check expiry
    if (claims.status == 'OPEN') {
      return of(user);
    }else{
      return of(null);
    }
  }

  private toCamelCase(key:any, value:any) {
    if (value && typeof value === 'object') {
      for (var k in value) {
        if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
          value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
          delete value[k];
        }
      }
    }
    return value;
  }
  checkSession(){
    return this.isSessionAuthenticated()
      .pipe(tap(authenticated => this.handleSessionAuth(authenticated)));
  }

  private handleSessionAuth(isAuthenticated: boolean) {
    return isAuthenticated;
  }
}
