import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export abstract class BaseHttpService {
  public serviceUri: string = "";
  defaultHeader: HttpHeaders | undefined;
  constructor(protected http: HttpClient) { }

  protected getDefaultAuthHttpRequestHeader(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'platform': 'pos'
    });
  }

  protected getMultiPartAuthHttpRequestHeader(): HttpHeaders {
    return new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'platform': 'pos'
    });
  }
}