import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizeGuard } from "./guards/authorize.guard";
import { PosGuard } from './guards/pos.guard';
import { AuthorizedComponent } from "./layout/authorized/authorized.component";

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: "",
        redirectTo: "session",
        pathMatch: "full",
      },
      {
        path: "customer", 
        children: [ 
          {
            path: "",
            loadChildren: () =>
              import("src/app/pages/customer/customer.module").then(m => m.CustomerModule),
          },
        ],
      },
      {
        path: "pos",
        component: AuthorizedComponent,
        children: [
          {
            path: "",
            redirectTo: "pos",
            pathMatch: "full",
          },
          {
            path: "",
            loadChildren: () =>
              import("src/app/pages/pos/pos.module").then(m => m.PosModule),
          },
        ],
      },
      {
        path: "session",
        component: AuthorizedComponent,
        canActivate: [AuthorizeGuard],
        children: [
          {
            path: "",
            redirectTo: "session",
            pathMatch: "full",
          },
          {
            path: "",
            canActivate: [AuthorizeGuard],
            loadChildren: () =>
              import("src/app/pages/session/session.module").then(m => m.SessionModule),
          },
        ],
      },
      {
        path: "login",
        loadChildren: () =>
          import("src/app/layout/unauthorized/unauthorized.module").then(
            m => m.UnauthorizedModule,
          ),
      },
      {
        path: "print",
        canActivate: [AuthorizeGuard],
        loadChildren: () =>
          import("src/app/layout/print/print.module").then(
            m => m.PrintModule,
          ),
      },
    ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
