import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { BaseHttpService } from "./base.http.service";

declare type LoginResponse = {
  accessToken:string;
  expires:number;
  refreshToken:string;
  code:string;
  detail:string;
};

@Injectable({
  providedIn: "root",
})
export class UserService extends BaseHttpService {
  constructor(private _httpClient: HttpClient) {
    super(_httpClient)
    this.serviceUri = `${environment.apiUrl}`;}

  login(username: string, password: string): Observable<LoginResponse> {
    return this._httpClient.post<LoginResponse>(`${this.serviceUri}/accounts/token`, {
      username,
      password,
    });
  }
}
