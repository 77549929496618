import { InjectionToken } from "@angular/core";
import { RxState } from "@rx-angular/state";

export interface AuthState {
  code: string;
  detail: string;
  accessToken: string;
  refreshToken: string;
  expires: number;
  hasError:boolean;
}
  
export const AUTH_STATE = new InjectionToken<
RxState<AuthState>
>("AUTH_STATE");