import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core"; 
import { Observable } from "rxjs"; 
import { environment } from "src/environments/environment";
import { 
  ISimpleMasterData,
} from "../models"; 
import { BaseHttpService } from "./base.http.service";

@Injectable({
  providedIn: "root",
})
export class MasterService extends BaseHttpService {

  constructor(protected httpCLient: HttpClient) {
    super(httpCLient);

    this.serviceUri = `${environment.apiUrl}/Masters`;
  }
  getOrderStatuses(): Observable<ISimpleMasterData[]> { 
    return this.http.get<ISimpleMasterData[]>(
      `${this.serviceUri}/Order/Statuses`, {headers: this.getDefaultAuthHttpRequestHeader()}
    );
  }
  getConcoctionTypes(): Observable<ISimpleMasterData[]> { 
    return this.http.get<ISimpleMasterData[]>(
      `${this.serviceUri}/ConcoctionTypes`, {headers: this.getDefaultAuthHttpRequestHeader()}
    );
  }
  getBanks(): Observable<ISimpleMasterData[]> { 
    return this.http.get<ISimpleMasterData[]>(
      `${this.serviceUri}/Banks`, {headers: this.getDefaultAuthHttpRequestHeader()}
    );
  }
  getCashlogTypes(): Observable<ISimpleMasterData[]> { 
    return this.http.get<ISimpleMasterData[]>(
      `${this.serviceUri}/CashLog/Types`, {headers: this.getDefaultAuthHttpRequestHeader()}
    );
  }
}
