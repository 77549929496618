<div
    [class]="customClass"
  infinite-scroll
  [infiniteScrollDistance]="distance"
  [infiniteScrollThrottle]="throttle"
  [scrollWindow]="false"
  (scrolled)="onScrollEvent()"
> 
    <ng-container *ngTemplateOutlet="dataTemplate">
    </ng-container>
</div>