import { AfterContentInit, Component, Inject, OnInit } from "@angular/core";
import { RxState } from "@rx-angular/state";
import { Subject } from "rxjs";
import { CurrentUser, SessionDetail } from "src/app/models";
import { AuthenticateService, BroadcastService } from "src/app/services";
import { AuthState, AUTH_STATE } from "./states";
import { Router } from "@angular/router";
import packageJson from '../../../../package.json';
// declare var window:any;

@Component({
  selector: 'app-authorized',
  templateUrl: './authorized.component.html',
  styleUrls: ['./authorized.component.scss']
})
export class AuthorizedComponent implements OnInit, AfterContentInit {
  public version: string = packageJson.version;
  onLogout$ = new Subject<void>();
  currentUser:CurrentUser | undefined;
  toggleLayer:boolean=false;
  sess:SessionDetail = JSON.parse(localStorage.getItem('session') as string);
  // window = window;
  constructor(@Inject(AUTH_STATE) private authState: RxState<AuthState>,
  private _authService: AuthenticateService, private router: Router, private broadcastService:BroadcastService) {
    this.connect();
  }  

  ngOnInit(): void { 
  }

  ngAfterContentInit(){
    this._authService.getUser().subscribe((x:any)=>{
      this.currentUser = x;
    });}

  private connect(): void {
  }
  onClosing() {
    setTimeout(() => {
      this.router.navigate(['pos/closing']);
    }, 200);
  }
  onPharmacy() {
    localStorage.removeItem('pharmacyId');
    setTimeout(() => {
      this.router.navigate(['session'])
      .then(() => {
        window.location.reload();
      });
    }, 200); 
  }
  onLogout() {
    localStorage.removeItem('uniqueId'),
    this.broadcastService.publish({ type: window.location.origin, payload: {}, msg:'logout' }),
    setTimeout(() => {
      this._authService.clearSession();
      this._authService.clearToken();
    }, 200);
  }
  clickToggle(){
    if(!this.toggleLayer)
    document.body.classList.add('noscroll');
    else
    document.body.classList.remove('noscroll');
    this.toggleLayer=!this.toggleLayer;
  }
  clickRefresh(){
    window.location.reload();
  }
}
