import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
import { ListModule } from './component/app-list/list.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule, 
    ListModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule, 
    ListModule
  ]
})

export class ShareModule {
  constructor() {
  }
}