import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loading: boolean = false;

  constructor() { }

  setLoading(loading: boolean) { 
    if(localStorage.getItem('nl'))
    this.loading=false;
    else
    this.loading = loading;
  }

  getLoading(): boolean {
    return this.loading;
  }
}