import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators'; 
import { BroadcastMessage } from '../models';
import { environment } from 'src/environments/environment';
import { BaseHttpService } from './base.http.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService extends BaseHttpService {

  broadcastChannel: any;
  onMessage = new Subject<any>();

  constructor(protected httpCLient: HttpClient) {
    super(httpCLient);

    this.serviceUri = `${environment.apiUrl}`;
    this.initialize();
  }

  initialize() {
    const name: string = window.location.origin;
    this.broadcastChannel = new BroadcastChannel(name);
    this.broadcastChannel.onmessage = (message:any) => this.onMessage.next(message.data);
  }

  publish(message: BroadcastMessage): void {
    this.broadcastChannel.postMessage(message);
  }

  messagesOfType(type: string): Observable<BroadcastMessage> {
    return this.onMessage.pipe(
      filter(message => message.type === type)
    );
  }

}