import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import queryString from 'query-string';
import { Observable } from "rxjs"; 
import { environment } from "src/environments/environment";
import { 
  Customers,
  PagingRequest,
  PagingResponse,
  postCustomer,
} from "../models"; 
import { BaseHttpService } from "./base.http.service";

@Injectable({
  providedIn: "root",
})
export class CustomerService extends BaseHttpService {

  constructor(protected httpCLient: HttpClient) {
    super(httpCLient);

    this.serviceUri = `${environment.apiUrl}/customers`;
  }

  getCustomers(
    request: PagingRequest<any>,
  ): Observable<PagingResponse<Customers>> {
    const q = queryString.stringify({
      search: request.search,  
      query: request.query,
      page: request.page,
      itemsPerpage: request.itemsPerpage,
      field: request.field,
      order: request.order,
    });
    return this.http.get<PagingResponse<Customers>>(
      `${this.serviceUri}?` + q, {headers: this.getDefaultAuthHttpRequestHeader()}
    );
  }
  addCustomer(
    customer: Partial<postCustomer>,
  ): Observable<any> {
    return this.http.post<any>(`${this.serviceUri}`, customer, {headers: this.getDefaultAuthHttpRequestHeader()});
  }
}